<template>
  <div class="home">
    <div class="navs" >
        <div class="navcont nav_o">
            <img src="../assets/img/nav2.png" style="position: absolute;left: 0px;top: 0px;z-index: -10;width: 100%;overflow:hidden">
            <p class="navcont_title">数据中台</p>
            <p class="navcont_enctitle" style="margin-top: -5px;">Product Introduction</p>
            <p class="navcont_title2" style="margin-top: 35px;">数据库功能</p>
            <p class="navcont_title2" style="margin-top: -5px;">不依赖于第三方数据库，完全国产化架构设计，去中心化分布式数据 存储，全程留痕，不可篡改。</p>
            <p class="navcont_title2" style="margin-top: 35px;">防火墙功能</p>
            <p class="navcont_title2" style="margin-top: -5px;">联盟性质的私有化部署，阻止一切外来侵袭，分权进行管理，通道相 互独立。</p>
            <p class="navcont_title2" style="margin-top: 35px;">加密功能</p>
            <p class="navcont_title2" style="margin-top: -5px;">通过安全证书加密数据，确保数据的安全性，可靠性。</p>
            <p class="navcont_title2" style="margin-top: 35px;">轻量化、高可用</p>
            <p class="navcont_title2" style="margin-top: -5px;">微服务架构，整合各类IoT设备的接口和三方接口，做到统一接口，应用。</p>
        </div>
        <div class="navcont nav_t">
            <img src="../assets/img/nav3.png" style="position: absolute;left: 0px;top: 0px;z-index: -10;width: 100%;overflow:hidden">
            <p class="navcont_title">分析展示中台</p>
            <p class="navcont_enctitle">Product Introduction</p>
            <p class="navcont_title2" style="margin-top: 35px;line-height: 30px;">
                引入数字孪生理念，让分析完的数据能更加直观的呈现在客户面前，是建设新型智慧城市的一条新兴技术路径，是城市智
                能化、运营可持续化的前沿先进模式，也是吸引高端智力资源共同参与，持续迭代更新的城市级创新平台。</p>
        </div>
        <div class="navcont nav_r">
            <img src="../assets/img/nav1.png" style="position: absolute;left: 0px;top: 0px;z-index: -10;width: 100%;overflow:hidden">
            <p class="navcont_title">管理执行中台</p>
            <p class="navcont_enctitle">Product Introduction</p>
            <p class="navcont_title2" style="margin-top: 35px;line-height: 30px;">
                引入数字孪生理念，让分析完的数据能更加直观的呈现在客户面前，是建设新型智慧城市的一条新兴技术路径，是城市智
                能化、运营可持续化的前沿先进模式，也是吸引高端智力资源共同参与，持续迭代更新的城市级创新平台。</p>
        </div>
    </div>
        <Service></Service>
    <Head :active="2"></Head>
    <Foot></Foot>
  </div>
</template>
<script>
import Foot from "./foot";
import Head from "./head";
import Service from "./service";
export default {
  components: { Foot, Head,Service },
  name: "index",
  data() {
    return {
      
    };
  },
  methods: {},

  watch: {},
};
</script>
<style scoped>
.navs {
  width: 100%;
  height: 100%;
  z-index: 3001;
}
.nav_cont {
  margin-left: 20px;
  opacity: 0.6;
  width: 166px;
  height: 42px;
  text-align: center;
  float: left;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 42px;
}
.home {
  width: 100%;
  position: relative;
  height: calc(100vh - 52px);
}
.navcont {
  padding: 200px 5% 0 2%;
  width: 26.33%;
  float: left;
  overflow: hidden;
  height: calc(100% - 200px);
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
}
.navcont_title {
  font-size: 26px;
  text-align: left;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #ffffff;
}

.navcont_enctitle {
  font-size: 26px;
  text-align: left;
  font-family: ArialMT;
  color: #ffffff;
}

.navcont_title2 {
  font-size: 16px;
  text-align: left;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #ffffff;
}
</style>
<style>
</style>