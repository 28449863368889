<template>
  <div class="serve">
    <div
      class="drag"
      v-show="contentshow"
      id="Drag"
      style="min-width:210px"
      ref="drag"
      :style="{ width: elementWidth + 'px', height: elementHeight + 'px',left:elementLeft+ 'px' ,top:elementTop+ 'px' }"
    >
    <div class="cont_lineright" @mousedown="startcontrightDrag"></div>
    <!-- <div class="cont_linetop" @mousedown="startconttopDrag"></div> -->
    <div class="cont_linebottom" @mousedown="startcontbottomDrag"></div>
      <div class="title" @mousedown="mousedown">
        <span>翌派科技{{ clickperson }}为您服务~</span>
        <div class="iconbox">
          <img
            v-if="!isfull"
            src="../assets/img/FULL.png"
            style="margin-right: 10px; cursor: pointer"
            @click="fullflagbox"
          />
          <img
            v-if="isfull"
            src="../assets/img/suoxiao.png"
            style="margin-right: 10px; cursor: pointer"
            @click="reduceflagbox"
          />
          <span class="close" @click="closebox">×</span>
        </div>
      </div>
      <div class="content" style="min-height:90px;max-height:767px" id="Chatinterface_box" :style="{ height: elementcontentHeight + 'px'}">
        <div
          class="contentlist"
          :class="item.role === 'assistant' ? 'kefuclass' : 'myclass'"
          v-for="(item, index) in answer"
          :key="index"
        >
          <img
            :src="clickperson === '小翌' ? img1 : img2"
            :class="item.role === 'assistant' ? 'kefuclassimg' : 'myclassimg'"
            v-if="item.role === 'assistant'"
          />
          <div class="words">
            {{ item.content }}
          </div>
          <img
            src="../assets/img/user.jpeg"
            :class="item.role === 'assistant' ? 'kefuclassimg' : 'myclassimg'"
            v-if="item.role === 'user'"
          />
        </div>
        <div class="contentlist" v-show="loading">
          <img
            :src="clickperson === '小翌' ? img1 : img2"
            class="kefuclassimg"
          />
          <div class="words">
            {{ clickperson }}：AI智能助手回复中，请稍等。。。
          </div>
        </div>
      </div>
      <div class="line" :style="{ top: dragLinePos + 'px' }" @mousedown="startDrag"></div>
      <div class="shuru">
        <div class="inputboc" v-if="inputshow" :style="{ height: elinputHeight + 'px'}">
            <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 3}"
            placeholder="请输入您需要咨询的问题，可通过shift+回车换行"
            v-model="textarea"
            @keydown.native="enterEvent"
          >
          </el-input>
        </div>
        <el-button size="mini" class="send" type="primary" :disabled="isdisabled" @click="send"
          >发送</el-button
        >
      </div>
    </div>
    <img src="../assets/img/contents.png" class="csontsimg" v-show="showHoverContent">
    <div class="guding container" v-show="isshowbox">
      <transition name="image-transition">
        <div
          :style="imageStyle"
          @mouseenter="stopMoving"
          @mouseleave="startMoving"
          @click="showcomtt('小翌')"
          class="moving-image"
          style="cursor: pointer"
        >
          <img
            style="width: 50px; height: 50px"
            src="../assets/img/women.png"
          />
          <span style="color: #fff">AI小翌</span>
        </div>
      </transition>
      <transition name="image-transition">
        <div
          :style="imageStyletwo"
          @mouseenter="stopMovingtwo"
          @mouseleave="startMovingtwo"
          @click="showcomtt('小派')"
          class="moving-imagetwo"
          style="cursor: pointer"
        >
          <img style="width: 50px; height: 50px" src="../assets/img/man.jpg" />
          <span style="color: #fff">AI小派</span>
        </div>
      </transition>
      <img src="../assets/img/wenhao.png" style="cursor:pointer" @mouseover="showHoverContent = true"
      @mouseout="showHoverContent = false">
    </div>
  </div>
</template>
<script>
export default {
  name: "index",
  data() {
    return {
      startD_top:0,
      startD_Bottom:0,
      textarea: "",
      startX:0,
      dragLinePos: 200, // 拖动线的初始位置
      isshowbox: false,
      isDragging: false, // 是否正在拖动
      startY: 0, // 拖动开始时的鼠标Y坐标
      contentshow: false,
      elementWidth: 400,
      // elinputWirth:320,
      elinputHeight:0,
      isdisabled:false,
      inputshow:true,
      showHoverContent: false,
      elementHeight: 450,
      elementLeft:20,
      elementTop:25,
      elementcontentHeight:300,
      isfull:false,
      tokens: "",
      answer: [],
      loading: false,
      img1: require("@/assets/img/women.png"),
      img2: require("@/assets/img/man.jpg"),
      clickperson: "",
      DragEl: null, //拖动元素
      totalHeight: null,
      totalWidth: null,
      // 位置差
      disX: 0,
      disY: 0,
      isMoving: true,
      isMovingtwo: true,
      imageStyle: {
        top: "0",
        left: "0",
      },
      imageStyletwo: {
        top: "150",
        left: "120",
      },
    };
  },
  methods: {
    startconttopDrag(event) {
      this.isDragging = true;
      this.startD_top = event.clientY;
      document.addEventListener('mousemove', this.conttopdrag);
      document.addEventListener('mouseup', this.contstoptopDrag);
    },
    startcontbottomDrag(event) {
      this.isDragging = true;
      this.startD_Bottom  = event.clientY;
      document.addEventListener('mousemove', this.contbottomdrag);
      document.addEventListener('mouseup', this.contstopbottomDrag);
    },
    startcontrightDrag(event) {
      this.isDragging = true;
      this.startX  = event.clientX;
      document.addEventListener('mousemove', this.contdrag);
      document.addEventListener('mouseup', this.contstopDrag);
    },
    startDrag(event) {
      this.isDragging = true;
      this.startY = event.clientY;
      document.addEventListener('mousemove', this.drag);
      document.addEventListener('mouseup', this.stopDrag);
    },
    contdrag(event) {
      if (this.isDragging) {
       if(event.clientX > 1905){
          this.contstopDrag 
        }else{
          const deltaX  = event.clientX - this.startX;
        this.elementWidth += deltaX;
        this.startX  = event.clientX;
        }
        
      }
    },
    contbottomdrag(event) {
      if (this.isDragging) {
        console.log('event.clientY',event.clientY)
        if(event.clientY > 960  || event.clientY < 240){
          this.contstopbottomDrag 
        }else{
        const deltaX  = event.clientY - this.startD_Bottom;
        this.elementHeight += deltaX;
        this.elementcontentHeight += deltaX;
        // this.elementTop = 283
        this.startD_Bottom  = event.clientY;
        }
        
      }
    },
    conttopdrag(event) {
      if (this.isDragging) {
        console.log('startD_top',this.startD_top)
        const deltaY = this.startD_top - event.clientY;
        this.elementHeight += deltaY;
        this.elementcontentHeight += deltaY;
        this.elementTop  -=deltaY
        this.startD_top = event.clientY;
      }
    },
    drag(event) {
      if (this.isDragging) {
        const deltaY = event.clientY - this.startY;
        this.elementcontentHeight += deltaY;
        this.startY = event.clientY;
      }
    },
    contstopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.contdrag);
      document.removeEventListener('mouseup', this.contstopDrag);
    },
    contstoptopDrag() {
      this.isDragging = false;
      this.startD_top = 0
      document.removeEventListener('mousemove', this.drag);
      document.removeEventListener('mouseup', this.contstoptopDrag);
    },
    contstopbottomDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.contbottomdrag);
      document.removeEventListener('mouseup', this.contstopbottomDrag);
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.drag);
      document.removeEventListener('mouseup', this.stopDrag);
    },
    stopMoving() {
      this.isMoving = false;
    },
    startMoving() {
      this.isMoving = true;
    },
    stopMovingtwo() {
      this.isMovingtwo = false;
    },
    startMovingtwo() {
      this.isMovingtwo = true;
    },
    fullflagbox() {
      this.inputshow = false
      setTimeout(() => {
        this.inputshow = true
      }, 100);
      this.isfull = true
      this.elementWidth = 1887;
      this.elementHeight = 900;
      this.elementcontentHeight = 670
      this.elementLeft = 20
      // this.elinputWirth = 1420
      // this.elinputHeight = 150
      this.elementTop = 26
    },
    reduceflagbox(){
      this.inputshow = false
      setTimeout(() => {
        this.inputshow = true
      }, 100);
    this.isfull = false
      this.elementWidth = 400;
      this.elementHeight = 425;
      this.elementLeft = 20
      // this.elinputWirth = 320 //输入框宽度
      // this.elinputHeight = 80 //输入框高度
      this.elementcontentHeight = 270
      this.elementTop = 25
    },
    moveImage() {
      const container = document.querySelector(".container");
      const image = document.querySelector(".moving-image");
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;
      const imageWidth = image.offsetWidth;
      const imageHeight = image.offsetHeight;
      console.log("container", container);
      console.log("image", image);
      console.log("containerWidth", containerWidth);
      console.log("containerHeight", containerHeight);
      console.log("imageWidth", imageWidth);
      console.log("imageHeight", imageHeight);
      let top = 0;
      let left = 0;
      let direction = 1;

      setInterval(() => {
        if (this.isMoving) {
          if (direction === 1) {
            left += 10;
            if (left + imageWidth >= containerWidth) {
              direction = 2;
            }
          } else if (direction === 2) {
            top += 10;
            if (top + imageHeight >= containerHeight) {
              direction = 3;
            }
          } else if (direction === 3) {
            left -= 10;
            if (left <= 0) {
              direction = 4;
            }
          } else if (direction === 4) {
            top -= 10;
            if (top <= 0) {
              direction = 1;
            }
          }

          this.imageStyle.top = `${top}px`;
          this.imageStyle.left = `${left}px`;
        }
      }, 100);
    },
    moveImagetwo() {
      const container = document.querySelector(".container");
      const image = document.querySelector(".moving-imagetwo");
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;
      const imageWidth = image.offsetWidth;
      const imageHeight = image.offsetHeight;

      let top = 150;
      let left = 120;
      let direction = 1;

      setInterval(() => {
        if (this.isMovingtwo) {
          if (direction === 1) {
            left += 10;
            if (left + imageWidth >= containerWidth) {
              direction = 2;
            }
          } else if (direction === 2) {
            top += 10;
            if (top + imageHeight >= containerHeight) {
              direction = 3;
            }
          } else if (direction === 3) {
            left -= 10;
            if (left <= 0) {
              direction = 4;
            }
          } else if (direction === 4) {
            top -= 10;
            if (top <= 0) {
              direction = 1;
            }
          }

          this.imageStyletwo.top = `${top}px`;
          this.imageStyletwo.left = `${left}px`;
        }
      }, 100);
    },
    mousedown(event) {
      // 1,计算位置差
      // 因为clientX和offsetLeft的属性返回的位置不一样 要相减得到鼠标在拖动元素内实际点击的位置
      // 后面每一次拖动时都要减去这个差值 否则就会造成你拖动的位置一直都是元素的左上角 而不是你之前点击的位置
      this.disX = event.clientX - this.DragEl.offsetLeft;
      this.disY = event.clientY - this.DragEl.offsetTop;

      //2， 获取拖动元素的高度和容器的高度 为了下面进行限制元素拖动的范围
      let dragHeight = this.DragEl.offsetHeight;
      let dragWidth = this.DragEl.offsetWidth;

      // 添加鼠标移动事件
      document.onmousemove = (el) => {
        // 3，获取鼠标移动位置
        let moveX = el.clientX - this.disX;
        let moveY = el.clientY - this.disY;
        // 4，限制拖动
        //控制范围：在元素 被拖拽的过程中 判断 元素的定位值 是否到达边界 如果到了 就不能在走了

        //4.1第一种 限制范围的判断
        // if(moveX <=0 || moveY <=0){ // 控制上边界和左边界
        //   return
        // }
        // if(moveX >= dragContainerWidth - dragWidth || moveY >= dragContainerHeight - dragHeight){
        //   return
        // }

        // 4.2 第二种限制方位的判断 建议使用第二种； 第一种靠边时经常会有边距，不太丝滑
        // 左边界
        if (moveX <= 20) {
          moveX = 20;
        }
        // 右边界 减去的值为div的宽度
        if (moveX >= this.totalWidth - 400) {
          moveX = this.totalWidth - 400;
        }
        // 上边界
        if (moveY <= 20) {
          moveY = 20;
        }
        // 上边界减去的值为div的高度
        if (moveY >= this.totalHeight - 400) {
          moveY = this.totalHeight - 400;
        }
        // 5, 开始移动
        this.DragEl.style.left = moveX + "px";
        this.DragEl.style.top = moveY + "px";
      };
      /* 6，鼠标抬起解除事件 */
      document.onmouseup = () => {
        document.onmousemove = null;
      };
    },
    showcomtt(mes) {
      this.clickperson = mes;
      this.contentshow = !this.contentshow;
    },
    enterEvent(e) {
      if (!e.shiftKey && e.keyCode == 13) {
         e.cancelBubble = true; //ie阻止冒泡行为
        e.stopPropagation();//Firefox阻止冒泡行为
        e.preventDefault(); //取消事件的默认动作*换行
        //以下处理发送消息代码
        this.send();
      }
      // if (!event.ctrlKey) {
      //   // 如果没有按下组合键ctrl，则会阻止默认事件
      //   event.preventDefault();
      //   this.send();
      // } else {
      //   // 如果同时按下ctrl+回车键，则会换行
      //   this.orderInfo += "\n";
      // }
      
    },
    closebox() {
      this.contentshow = false;
      this.isMoving = true;
      this.textarea = "";
      this.answer = [];
    },
    send() {
      if(!this.isdisabled){
          this.isdisabled = true
          this.answer.push({
          content: this.textarea,
          role: "user",
        });
        setTimeout(() => {
          this.textarea = "";
          this.loading = true;
          console.log();
          this.$nextTick(() => {
            let div = document.getElementById("Chatinterface_box");
            div.scrollTop = div.scrollHeight;
          });
        }, 10);
        this.$axios
          .post("https://esys.epai.tech:15559/basic/wenyan", {
            messages: this.answer,
          })
          .then((res) => {
            let results = JSON.parse(res.data.result);
            console.log("res", results);
            if (res.status === 200) {
              this.loading = false;
              this.answer.push({
                content: results.result,
                role: "assistant",
              });
              this.isdisabled = false
              this.$nextTick(() => {
                let div = document.getElementById("Chatinterface_box");
                div.scrollTop = div.scrollHeight;
              });
            }
          })
          .catch((err) => {
            console.log(err, "res");
          });
      }
      }
      
  },
  mounted() {},
  created() {
    // this.$axios
    //   .post(
    //     "/oauth/2.0/token?grant_type=client_credentials&client_id=ZZwV24tOt8fb1O5fC1DREkx1&client_secret=fvM1Vk30Y86LUspq5C5aNZsdU7VU0Cyr"
    //   )
    //   .then((res) => {
    //     console.log("res", res);
    //     this.tokens = res.data.access_token;
    //   })
    //   .catch((err) => {
    //     console.log(err, "res");
    //   });
    if (
      localStorage.getItem("islogin") === true ||
      localStorage.getItem("islogin") === "true"
    ) {
      this.$nextTick(() => {
        this.isshowbox = true;
        setTimeout(() => {
          this.moveImage();
          this.moveImagetwo();
          this.DragEl = document.getElementById("Drag");
          this.totalHeight = window.innerHeight;
          this.totalWidth = window.innerWidth;
        }, 500);
      });
    } else {
      this.isshowbox = false;
    }
  },
  watch: {},
};
</script>
<style scoped>
.moving-image {
  position: absolute;
  transition: top 0.1s, left 0.1s;
  width: 50px;
  z-index: 9998;
  height: 50px;
  border-radius: 50%;
}
.moving-imagetwo {
  position: absolute;
  transition: top 0.1s, left 0.1s;
  width: 50px;
  height: 50px;
  z-index: 9998;
  border-radius: 50%;
}
.container {
  position: fixed;
  left: 0;
  bottom: 53px;
  width: 200px !important;
  height: 200px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
}

.contentlist {
  width: calc(100% - 20px);
  display: inline-block;
  border-radius: 20px;
  font-size: 14px;
  padding: 10px;
  float: left;
  text-align: left;
  display: flex;
}
.content::-webkit-scrollbar {
  width: 5px;
}
/* 滚动槽 */
.content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
/* 滚动条滑块 */
.content::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}
.kefuclass {
  justify-content: start;
}
.kefuclassimg {
  float: left;
  width: 30px;
  height: 30px;
}
.myclassimg {
  float: right;
  width: 30px;
  height: 30px;
}
.csontsimg{
    position: fixed;
    left: 20px;
    z-index: 9999;
    width: 244px;
    bottom: 175px;
    height: 299px;
}
.serve {
  /* width: 300px; */
  /* user-select: none; */
  /* height: 420px; */
  /* position: fixed; */
  /* left: 20px; */
  /* bottom: 180px; */
  /* position: relative; */
}
.shuru {
  width: 100%;
  margin-top: 8px;
  /* height: 60px; */
  padding-bottom: 10px;
  position: relative;
}
.serveword {
  color: #fff;
  font-size: 20px;
}
.close {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #fff;
  text-align: center;
  line-height: 15px;
  cursor: pointer;
}
.line{
  height: 1px;
  width: 100%;
  cursor: n-resize;
  background: black;
}
.iconbox {
  display: flex;
  align-content: center;
}
.title {
  user-select:none;
  color: #fff;
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #6696ff !important;
  background-image: none !important;
  height: 40px;
  text-align: left;
  font-size: 12px;
  padding: 0 10px;
  line-height: 40px;
}
.content {
  width: calc(100% - 20px);
  padding: 10px;
  background: #f5f5f5;
  /* height: 270px; */
  overflow: auto;
}
.kefubox {
  display: flex;
  cursor: pointer;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}
.women {
  width: 50px;
  color: #fff;
  text-align: center;
  align-items: center;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.myclass {
  float: right;
  justify-content: flex-end;
}
.kefuclass .words {
  background: #fff;
  display: inline-block;
  border-radius: 10px;
  font-size: 14px;
  padding: 5px 10px;
  float: right;
}
.myclass .words {
  background: #95ec69;
  display: inline-block;
  border-radius: 10px;
  font-size: 14px;
  padding: 5px 10px;
  float: right;
}
.cont_linetop{
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 4px;
  cursor: n-resize;
}
.cont_linebottom{
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  cursor: n-resize;
}
.cont_lineright{
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  cursor: w-resize;
  width: 2px;
}
.drag {
  /* width: 400px;
  height: 400px; */
  z-index: 9999;
  position: fixed;
  padding-bottom: 10px;
  /* left: calc(50% - 200px);
  top: calc(50% - 200px); */
  overflow: hidden;
  /* border-radius: 10px; */
  background: #fff;
}
</style>
<style>
.send {
  position: absolute;
  right: 5px !important;
  top: 15px !important;
}
.el-textarea .el-textarea__inner {
  /* height: 100%; */
  width: 100%;
  overflow: auto;
  max-height: 90px!important;
  padding-bottom: 10px;
}
.inputboc{
  padding-left: 10px;
  margin-right: 10px;
  width: calc(100% - 80px);
  /* overflow: auto; */
}
.el-button--primary {
  background: #ff865b;
  border: none;
  top: 0;
}
.el-button--primary:hover {
  background: #ff865b;
  border: none;
}
</style>