<template>
  <div class="login-container">
    <div class="login-icon-group">
      <div class="login-icon-group-title">
        <img src="../assets/img/bg-logo.png" />
      </div>
    </div>
    <div class="login-content">
      <div class="login-content-main">
        <h4 class="login-content-title ml15">用户注册</h4>
        <div class="loginindex">
          <el-tabs v-model="tabsActiveName">
            <el-tab-pane label="手机号注册" name="mobile">
              <el-form
                size="large"
                class="login-content-form"
                ref="ruleFormRef"
                :rules="rules"
                :model="ruleForm"
              >
                <el-form-item class="login-animation1" prop="phone">
                  <el-input
                    type="text"
                    placeholder="请输入手机号"
                    ref="phonerefs"
                    v-model="ruleForm.phone"
                    clearable
                    maxlength="11"
                    autocomplete="off"
                  >
                    <template #prefix>
                      <i class="iconfont icon-dianhua el-input__icon"></i>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item class="login-animation2" prop="code">
                  <el-col :span="15">
                    <el-input
                      ref="wordrefs"
                      type="text"
                      maxlength="6"
                      placeholder="请输入验证码"
                      v-model="ruleForm.code"
                      clearable
                      autocomplete="off"
                      @keyup.enter="submitForm('ruleFormRef')"
                    >
                      <template #prefix>
                        <el-icon class="el-input__icon"
                          ><ele-Position
                        /></el-icon>
                      </template>
                    </el-input>
                  </el-col>
                  <el-col :span="1"></el-col>
                  <el-col :span="8">
                    <el-button
                      class="login-content-code"
                      style=""
                      @click="obtaincode"
                      :disabled="codedisabled"
                      >{{ codemes }}</el-button
                    >
                  </el-col>
                </el-form-item>
                <el-form-item class="login-animation3">
                  <el-button
                    type="primary"
                    class="login-content-submit"
                    @click="submitForm('ruleFormRef')"
                  >
                    <span>注册</span>
                  </el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div style="color: #3a62d7;float:right;cursor:pointer" @click="goregister">去登录></div>

        <!-- <div v-if="isScan"><Scan /></div> -->
        <div class="login-content-main-sacn" @click="isScan = !isScan">
          <i
            class="iconfont"
            :class="isScan ? 'icon-diannao1' : 'icon-barcode-qr'"
          ></i>
          <div class="login-content-main-sacn-delta"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "index",
  data() {
    var validatephone = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入手机号码'));
        } else {
            var passwordreg =
              /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/;
            console.log(passwordreg.test(value));
            if (!passwordreg.test(value)) {
              callback(new Error("请输入正确的手机号码哦!"));
              this.phoneisready = false;
            } else {
              this.phoneisready = true;
              callback();
            }
        }
      };
    return {
      tabsActiveName: "mobile",
      isScan: false,
      codedisabled: false,
      phoneisready: false,
      codemes:'获取验证码',
      ruleForm: {
        phone: "",
        code: "",
      },
      rules: {
        phone: [
            { validator: validatephone, trigger: 'blur' }
          ],
      },
    };
  },
  methods: {
    goregister(){
      this.$router.push('/login')

    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .get("https://esys.epai.tech:15559/auth/verifyPhoneCode?phone="+this.ruleForm.phone+'&code='+this.ruleForm.code+'&type=2')
            .then((res) => {
              console.log("res", res);
              if (res.data.token) {
                this.$message({
                  message: "注册成功",
                  type: "success",
                });
                this.$router.push('/login')
              }else{
                this.$message.error(res.data.zhmessage);
              }
            })
            .catch((err) => {
              console.log(err, "res");
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    tackBtn()  {
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.codemes = "获取验证码";
          this.codedisabled = false;
        } else {
          this.codedisabled = true;
          this.codemes = time + "秒后重试";
          time--;
        }
      }, 1000);
    },
    obtaincode() {
      
      if (this.phoneisready) {
        this.$axios.post("https://esys.epai.tech:15559/auth/phonecode",{
          type:'2',
          phone:this.ruleForm.phone
        })
        .then((res) => {
          console.log("res", res);
          if(res.data.status){
            this.$message({
              message: '验证码发送成功',
              type: 'success'
            });
            this.tackBtn();
          }else{
            this.$message.error(res.data.zhmessage);
          }
        })
        .catch((err) => {
          console.log(err, "res");
        });
      } else {
        this.$message.error("请输入正确的手机号");
      }
    },
  },

  watch: {},
};
</script>

<style scoped>
.login-icon-group {
  position: relative;
  width: 100%;
  height: 100%;
}
.login-icon-group-title {
  top: 45%;
  position: absolute;
  padding: 0px 0 0 80px;
  display: flex;
  left: 200px;
  align-items: center;
}
.login-carousel {
  left: 100px;
  top: 101px;
  position: absolute;
  width: 1087px;
  height: 627px;
}
.login-content {
  width: 450px;
  padding: 20px;
  position: absolute;
  right: 210px;
  top: calc(50% - 200px);
  background: #fff;
  border: 5px solid #d7e5ff;
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
}
.login-content-main {
  margin: 0 auto;
  width: 80%;
}
.login-content-title {
  color: #303133;
  font-weight: 800;
  font-size: 30px;
  text-align: center;
  letter-spacing: 4px;
  margin: 15px 0 30px;
  white-space: nowrap;
  z-index: 5;
  position: relative;
  transition: all 0.3s ease;
}
.login-content-main-sacn {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  overflow: hidden;
  cursor: pointer;
  transition: all ease 0.3s;
  color: var(--el-text-color-primary);
}
.login-content-form{
  margin-top: 20px;
}
.login-container {
  width: 100%;
  height: 100vh;
  position: relative;
  background-image: url(../assets/img/bg.png);
  background-size: 100% 100%;
}
</style>
<style>
.loginindex .el-tabs .el-tabs__hea der .el-tabs__nav-wrap .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}
.login-content-submit{
  width: 100%;
    letter-spacing: 2px;
    color: #fff;
    background: #3a62d7!important;
    font-weight: 300;
    margin-top: 15px;

}
.login-content-submit:hover{
    background: #3a62d7!important;
}
.login-content-code{
  border: 1px solid #dcdfe6!important;;
}
</style>

