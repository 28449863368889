<template>
  <div >
    <div class="bottomword">
        版权所有© 2021 上海翌派科技有限公司 <a href ="https://beian.miit.gov.cn/" target="_blank" style="color:#fff;">沪ICP备17037132号-1</a>
    </div>
    </div>
</template>
<script>
export default {
  name: "foot",
};
</script>
<style scoped>
.bottomword {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #6c7293;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 52px;
  text-align: center;
  background: #000;
  line-height: 52px;
}


</style>
