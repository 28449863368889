<template>
  <div class="box">
    <el-input v-model="input"></el-input>
    <el-button type="primary" style="width: 80px; height: 30px;float:left;line-height:10px;margin-top:10px;margin-bottom:10px" @click="submit">提问</el-button>
    <el-input
    v-loading="loading"
      type="textarea"
      :rows="15"
      placeholder="请输入内容"
      v-model="textarea"
    >
    </el-input>
  </div>
</template>
<script>
import { post } from "../request/http";
export default {
  name: "AddActivity",
  components: {},
  data() {
    return {
      input: "",
      textarea: "",
      loading:false,
      tokens:''
    };
  },
  created() {
    // getuserinfo({
    //     grant_type: 'client_credentials',
    //     client_id:'L5Fh6HOTFGf8pz7AeExTwSDD',
    //     client_secret:'lnLvbCr1FGAYeMF3gLCQ4zhbKiFQgZGb'
    // }).then((res) => {
    //   console.log("获取用户登录信息", res);

    // });
    this.$axios
      .post("/oauth/2.0/token?grant_type=client_credentials&client_id=L5Fh6HOTFGf8pz7AeExTwSDD&client_secret=lnLvbCr1FGAYeMF3gLCQ4zhbKiFQgZGb")
      .then((res) => {
        console.log("res", res);
        this.tokens = res.data.access_token
      })
      .catch((err) => {
        console.log(err, "res");
      });
  },
  mounted() {},
  methods: {
    submit(){
      this.textarea = ''
      this.loading = true
      this.$axios
      .post("/rpc/2.0/ai_custom/v1/wenxinworkshop/chat/completions_pro?access_token="+this.tokens,{
        "messages": [
            {
                "role": "user",
                "content": this.input
            }
        ]
      })
      .then((res) => {
        console.log("res", res);
        if(res.status === 200){
          this.loading = false
          this.textarea = res.data.result
        }
        
      })
      .catch((err) => {
        console.log(err, "res");
      });
    },
  },
};
</script>
<style scoped>
.box {
  width: 500px;
  height: 500px;
}
</style>
