<template>
  <div class="home">
        <div class="About" id="AboutId" name="jumpitem">
            <div class="lines_one"></div>
            <div class="lines_two"></div>
            <div class="lines_three"></div>
            <div class="About_bg"></div>
            <div class="About_bgword">ABOUT</div>
            <div class="About_title">关于翌派</div>
            <div class="About_word">翌派科技，成立于2017年，是一家专注于金融和智慧城市领域的创新型科技公司。经过六年的不懈努力，我们已经成功研发了多款领先产品，包括金融量化交易平台、智慧城市数据中台、业务中台和AI中台等，为多地政府提供智慧化解决方案和个性化应用平台。公司拥有多个专业的研发团队，致力于产品的持续优化和升级。我们紧跟科技发展趋势，不断探索新技术，以最先进的技术为各种业务场景提供强大支持。
                </br>
                </br>
                通过对三大类技术中台的设计、开发、集成，根据政府和企业的自身需求，为城市管理 运营、部门管理运营和企业管理运营提供个性化的系统集成解决方案。
            </div>
            <div class="icons">
                <div class="icon_o">
                    <div class="icon_o_c">
                        <img src="../assets/img/img1.png" style="width: 40px;height: 40px;transform: rotate(-90deg);">
                        <span class="icon-word">分析展示中台</span>
                    </div>
                </div>
                <div class="icon_o">
                    <div class="icon_o_c">
                        <img src="../assets/img/img2.png" style="width: 40px;height: 40px;transform: rotate(-90deg);">
                        <span class="icon-word">管理执行中台</span>
                    </div>
                </div>
                <div class="icon_o">
                    <div class="icon_o_c">
                        <img src="../assets/img/img3.png" style="width: 60px;height: 40px;transform: rotate(-90deg);">
                        <div class="icon-word">数据中台</div>
                    </div>
                </div>

            </div>

        </div>
        <Service></Service>
     <Head :active="1"></Head>
        <Foot></Foot>
    </div>
</template>
<script>
import Service from "./service";
import Foot from "./foot";
import Head from "./head";
export default {
  components: { Foot, Head,Service },
  name: "index",
  data() {
    return {
    };
  },
  methods: {},

  watch: {},
};
</script>
<style scoped>
.home {
  width: 100%;
  position: relative;
  height: calc(100vh - 52px);
}
.lines_one {
  width: 1px;
  z-index: 20;
  position: absolute;
  left: 168px;
  top: 0%;
  height: 100%;
  background: #ffffff;
  opacity: 0.06;
}

.lines_two {
  width: 1px;
  z-index: 20;
  position: absolute;
  left: 40%;
  top: 0%;
  height: 100%;
  background: #ffffff;
  opacity: 0.06;
}

.lines_three {
  width: 1px;
  z-index: 20;
  position: absolute;
  left: 70%;
  top: 0%;
  height: 100%;
  background: #ffffff;
  opacity: 0.06;
}

.About {
  width: 100%;
  height: 100%;
  background: #171919;
  position: relative;
}

body::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  background-color: transparent;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
body::-webkit-scrollbar-track {
  background-color: #555;
  border-radius: 1px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/*定义滚动条轨道 内阴影+圆角*/
body::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 1px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.About_bgword {
  font-size: 200px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #ffffff;
  line-height: 156px;
  position: absolute;
  left: -5%;
  z-index: 50;
  opacity: 0.02;
  top: 13%;
}

.About_title {
  font-size: 40px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #ffffff;
  z-index: 60;
  position: absolute;
  left: 50%;
  margin-left: -350px;
  top: 12%;
}

.About_word {
  width: 700px;
  font-size: 18px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  position: absolute;
  margin-left: -350px;
  z-index: 60;
  opacity: 0.7;
  left: 50%;
  top: 19%;
  line-height: 32px;
}

.icons {
  height: 400px;
  width: 400px;
  color: #ffffff;
  position: absolute;
  margin-left: -205px;
  left: 50%;
  top: 50%;
  transform: rotate(45deg);
  line-height: 32px;
}

.icon_o {
  width: 185px;
  /* transform: rotate(5deg); */
  margin-left: 15px;
  margin-top: 15px;
  height: 185px;
  float: left;
  background: rgba(16, 33, 49, 0.5);
  /* box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.49); */
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* opacity: 0.5; */
}

.icon_o_c {
  width: 104px;
  transform: rotate(45deg);
  height: 114px;
  padding-left: 10px;
  background: linear-gradient(180deg, #253659 0%, #102131 100%);
  background: #253659;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #ff865b;
  line-height: 110px;
  opacity: 1;
  text-align: center;
}

.icon-word {
  width: 108px;
  /* height: 112px; */
  margin-left: -24px;
  transform: rotate(-90deg);
  opacity: 1;
  line-height: 22px;
}

.About_bg {
  background-image: url(../assets/img/Map1.png);
  background-size: 100%;
  height: 534px;
  width: 1246px;
  position: absolute;
  left: 10%;
  top: 38%;
  background-repeat: no-repeat;
}
</style>
<style>
.luobo .el-carousel .el-carousel__indicators--horizontal {
  bottom: 5% !important;
  right: 5% !important;
  left: unset !important;
  transform: translateX(0%) !important;
}
.luobo .el-carousel .el-carousel__container {
  height: 100%;
}
.luobo
  .el-carousel
  .el-carousel__indicators
  .el-carousel__indicator
  .el-carousel__button {
  width: 200px !important;
  height: 4px !important;
  background: #ffffff !important;
  margin-left: 30px !important;
}

.luobo .el-carousel .el-carousel__indicators .is-active .el-carousel__button {
  background: #ff865b !important;
}
</style>