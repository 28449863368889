<template>
  <div class="container">
    <div class="resizable" :style="{ width: width + 'px', height: height + 'px' }">
      <div class="handle top" @mousedown="startResize($event, 'top')"></div>
      <div class="handle right" @mousedown="startResize($event, 'right')"></div>
      <div class="handle bottom" @mousedown="startResize($event, 'bottom')"></div>
      <div class="handle left" @mousedown="startResize($event, 'left')"></div>
      <!-- 这里放你的内容 -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      width: 300, // div的初始宽度
      height: 300, // div的初始高度
      startX: 0, // 拖动开始时的鼠标X坐标
      startY: 0, // 拖动开始时的鼠标Y坐标
      resizing: false, // 是否正在调整大小
      resizeDirection: '', // 调整大小的方向
    };
  },
  methods: {
    startResize(event, direction) {
      this.resizing = true;
      this.resizeDirection = direction;
      this.startX = event.clientX;
      this.startY = event.clientY;
      document.addEventListener('mousemove', this.resize);
      document.addEventListener('mouseup', this.stopResize);
    },
    resize(event) {
      if (this.resizing) {
        const deltaX = event.clientX - this.startX;
        const deltaY = event.clientY - this.startY;
        if (this.resizeDirection === 'top') {
          this.height -= deltaY;
        } else if (this.resizeDirection === 'right') {
          this.width += deltaX;
        } else if (this.resizeDirection === 'bottom') {
          this.height += deltaY;
        } else if (this.resizeDirection === 'left') {
          this.width -= deltaX;
        }
        this.startX = event.clientX;
        this.startY = event.clientY;
      }
    },
    stopResize() {
      this.resizing = false;
      this.resizeDirection = '';
      document.removeEventListener('mousemove', this.resize);
      document.removeEventListener('mouseup', this.stopResize);
    },
  },
};
</script>

<style>
.container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.resizable {
  position: absolute;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%, -50%); */
  background-color: #f0f0f0;
  overflow: auto;
}

.handle {
  position: absolute;
  background-color: #ccc;
  cursor: pointer;
}

.top {
  top: -5px;
  left: 0;
  right: 0;
  height: 10px;
}

.right {
  top: 0;
  bottom: 0;
  right: -5px;
  width: 10px;
}

.bottom {
  bottom: -5px;
  left: 0;
  right: 0;
  height: 10px;
}

.left {
  top: 0;
  bottom: 0;
  left: -5px;
  width: 10px;
}
</style>
