// 在http.js中引入axios
import axios from 'axios'; // 引入axios
import QS from 'qs'; // 引入qs模块，用来序列化post类型的数据，后面会提到
// 环境的切换
if (process.env.NODE_ENV == 'development') {
// axios.defaults.baseURL = 'http://59.50.38.127:8089';
axios.defaults.baseURL = '/api';
// window.localStorage.configurl = 'http://59.50.38.127:8089';
} else if (process.env.NODE_ENV == 'debug') {
    // axios.defaults.baseURL = 'http://59.50.38.127:8089';
} else if (process.env.NODE_ENV == 'production') {
    // axios.defaults.baseURL = 'http://59.50.38.127:8089';
}
// if (process.env.NODE_ENV == 'development') {
//     axios.defaults.baseURL = 'https://aip.baidubce.com';
// } else if (process.env.NODE_ENV == 'debug') {
//     axios.defaults.baseURL = 'https://aip.baidubce.com';
// } else if (process.env.NODE_ENV == 'production') {
//     axios.defaults.baseURL = 'https://aip.baidubce.com';
// }
axios.defaults.timeout = 6000000;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/octet-stream';

// axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
// 请求拦截器

    // 响应拦截器
axios.interceptors.response.use(
        response => {
            if (response.status === 200) {
                return Promise.resolve(response);
            } else {
                return Promise.reject(response);
            }
        },
        error => {
            if (error.response.status) {
                return Promise.reject(error.response);
            }
        }
    )
    /**
     * get方法，对应get请求
     * @param {String} url [请求的url地址]
     * @param {Object} params [请求时携带的参数]
     */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}

/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params) {
    console.log('url, params', url, params)
    return new Promise((resolve, reject) => {
        axios.put(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
export function uploadpost(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params, { headers: { "Content-Type": "multipart/form-data" } })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}