import Vue from 'vue'
import Home from '../components/home'
import Test from '../components/index'
import Aboutus from '../components/aboutus'
import Product from '../components/product'
import Contact from '../components/contact'
import Introduce from '../components/introduce'
import Programme from '../components/programme'
import Login from '../components/login'
import Register from '../components/register'
import Testtwo from '../components/test'
import Router from 'vue-router'

Vue.use(Router)

export const constantRouterMap = [{
    path: '/',
    component: Test,
    children: [
        {
            path: '/index',
            component: () => import('@/components/index.vue')
        }
    ]

}, {
    path: '/index',
    component: Test,
    children: [
        {
            path: '/index',
            component: () => import('@/components/totttt.vue')
        }
    ]

}, {
    path: '/aboutus',
    component: Aboutus,
    children: [
        {
            path: '/aboutus',
            component: () => import('@/components/aboutus.vue')
        }
    ]

},{
    path: '/product',
    component: Product,
    children: [
        {
            path: '/product',
            component: () => import('@/components/product.vue')
        }
    ]

},{
    path: '/contact',
    component: Contact,
    children: [
        {
            path: '/contact',
            component: () => import('@/components/contact.vue')
        }
    ]

}, {
    path: '/introduce',
    component: Introduce,
    children: [
        {
            path: '/introduce',
            component: () => import('@/components/introduce.vue')
        }
    ]

}, {
    path: '/programme',
    component: Programme,
    children: [
        {
            path: '/programme',
            component: () => import('@/components/programme.vue')
        }
    ]

}, {
    path: '/login',
    component: Login,
    children: [
        {
            path: '/login',
            component: () => import('@/components/login.vue')
        }
    ]

}, {
    path: '/register',
    component: Register,
    children: [
        {
            path: '/register',
            component: () => import('@/components/register.vue')
        }
    ]

}, {
    path: '/testtwo',
    component: Testtwo,
    children: [
        {
            path: '/test',
            component: () => import('@/components/test.vue')
        }
    ]

}



]

const router = new Router({
    routes: constantRouterMap
})

export default router