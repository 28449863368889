<template>
  <div >
        <!-- <div class="lines"></div> -->
        <div class="home">
            <div style="width: 100%;height: 100%" class="luobo">
                <el-carousel style="height:100%">
                    <el-carousel-item v-for="(item,index) in imgnav" style="height:100%" :key="index">
                        <img :src="item.imgs" class="imgsnav">
                    </el-carousel-item>
                </el-carousel>
                <div class="luobonum">
                    <span class="luobonum_span">01</span>
                    <span class="luobonum_span">02</span>
                    <span class="luobonum_span">03</span>
                    <span class="luobonum_span">04</span>
                </div>
            </div>
            <Head :active="0"></Head>
            <div class="word_o">
                专注于数字化转型</br>提供丰富的解决方案
            </div>
            <div class="word_t">
                Focus on digital transformation
			</br>Provide rich solutions
            </div>
            <!-- <div class="down">
                <img src="../assets/img/DownArrows.png">
            </div> -->
        </div>
        <Service></Service>
        <Foot></Foot>
    </div>
</template>
<script>
import Foot from "./foot";
import Head from "./head";
import Service from "./service";
export default {
  components: { Foot,Head,Service },
  name: "index",
  data() {
    return {
      imgnav: [
        { imgs: require("../assets/img/hometopbg.png") },
        { imgs: require("../assets/img/hometopbg.png") },
        { imgs: require("../assets/img/hometopbg.png") },
        { imgs: require("../assets/img/hometopbg.png") },
      ],
    };
  },
  methods: {

  },
  created(){
    
  },

  watch: {},
};
</script>
<style scoped>
body,
html {
  margin: 0;
  padding: 0;
}
.imgsnav{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.down {
  position: absolute;
  z-index: 2;
  bottom: 0px;
  left: 200px;
  bottom: 20px;
}




.lines {
  width: 1px;
  z-index: 2000;
  position: absolute;
  left: 168px;
  top: 0%;
  height: 100%;
  background: #ffffff;
  opacity: 0.25;
}

.luobonum {
  position: absolute;
  z-index: 5000;
  bottom: 2%;
  right: 5%;
}

.luobonum_span {
  text-align: left;
  width: 200px;
  padding: 12px 4px;
  font-size: 24px;
  display: block;
  float: left;
  margin-left: 30px;
  font-family: Helvetica;
  color: #ffffff;
}

.home {
  width: 100%;
  position: relative;
  user-select:none;
  height: calc(100vh - 52px);
}



.word_o {
  font-size: 50px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #ffffff;
  z-index: 999;
  position: absolute;
  text-align: right;
  top: 60%;
  right: 5%;
}

.word_t {
  font-size: 26px;
  font-family: MicrosoftYaHei;
  z-index: 999;
  color: #ffffff;
  opacity: 0.6;
  position: absolute;
  text-align: right;
  top: 75%;
  right: 5%;
}

</style>
<style>


.luobo .el-carousel .el-carousel__indicators--horizontal {
  bottom: 5% !important;
  right: 5% !important;
  left: unset !important;
  transform: translateX(0%) !important;
}
.luobo .el-carousel .el-carousel__container{
    height: 100%;
}
.luobo
  .el-carousel
  .el-carousel__indicators
  .el-carousel__indicator
  .el-carousel__button {
  width: 200px !important;
  height: 4px !important;
  background: #ffffff !important;
  margin-left: 30px !important;
}

.luobo .el-carousel .el-carousel__indicators .is-active .el-carousel__button {
  background: #ff865b !important;
}
</style>