<template>
  <div class="head">
    <div class="toito">
      <img src="../assets/img/lo.png" />
      <span class="logotil">翌派科技</span>
    </div>
    <div class="nav">
      <div
        class="nav_cont"
        v-for="(item, index) in nav"
        :key="index"
        @click="navclick(item, index)"
        :class="{ tabspanactive: index === active }"
      >
      <!-- {{ item.name }} -->
        <span>{{ item.name }}</span>
        
        <!-- <el-dropdown @command="handleCommand" trigger="click" style="float:left"   v-if="item.name==='解决方案&案例分享'">
          <el-button type="primary">
            解决方案&案例分享<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown" >
            <el-dropdown-item command="h">城运管理系统</el-dropdown-item>
            <el-dropdown-item command="a">一标六实智慧公安平台</el-dropdown-item>
            <el-dropdown-item command="b">管廊与管网</el-dropdown-item>
            <el-dropdown-item command="c">智慧办公</el-dropdown-item>
            <el-dropdown-item command="c">智慧会议</el-dropdown-item>
            <el-dropdown-item command="c">RPA</el-dropdown-item>
            <el-dropdown-item command="c">智慧物流</el-dropdown-item>
            <el-dropdown-item command="c">量化交易</el-dropdown-item>
            <el-dropdown-item command="c">智能投资管理系统</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
        
      </div>
      <div class="nav_cont"  @click="gofa" :class="{ tabspanactive:active ===5 }">解决方案&案例分享</div>
      <div class="nav_cont" v-if="!islogin" @click="goligin">登录</div>
      <div class="nav_cont" v-if="islogin" @click="gooutligin">退出登录</div>
      <ul  class="uls" v-show="showuls" >
          <li v-for="(item,index) in pargnav" :key="index" @click="lisclick(item,index)">{{item.name}}</li>
        </ul>
    </div>
    
  </div>
</template>
<script>
import Foot from "./foot";
export default {
  components: { Foot },
  name: "index",
  props: {
    active: {
      type: Number,
    },
  },
  data() {
    return {
      nav: [
        { name: "首页" },
        { name: "关于翌派" },
        { name: "三大中台介绍" },
        { name: "产品介绍" },
        // { name: "解决方案&案例分享" },
        { name: "联系我们" },
        // { name: "登录" },
      ],
      pargnav:[
        {name:'城运管理系统'},
        {name:'一标六实智慧公安平台'},
        {name:'管廊与管网'},
        {name:'智慧办公'},
        {name:'智慧会议'},
        {name:'RPA'},
        {name:'智慧物流'},
        {name:'量化交易'},
        {name:'智能投资管理系统'},
      ],
      activeshare: 0,
      navmes: "",
      showuls:false,
      islogin: false,
    };
  },
  created(){
    if(localStorage.getItem('islogin') === true || localStorage.getItem('islogin') === 'true'){
      this.islogin = true
    }else{
      this.islogin = false
    }
  },
  methods: {
    lisclick(item,index){
      this.$parent.test(index)
      this.showuls = false
    },
    gofa(){
      console.log('?',this.$route)
      if(this.$route.path === '/programme'){
        this.showuls = !this.showuls
      }else{
      this.$router.push("/programme");

      }
    },
    handleCommand(command){
      console.log('co',command)
    },
    goligin(){
      this.$router.push("/login")
    },
    gooutligin(){
      localStorage.setItem('islogin', false);
      this.$router.push("/login")
    },
    navclick(item, index) {
      console.log(index)
      switch (index) {
        case 0:
          this.$router.push("/index");
          break;
        case 1:
          this.$router.push("/aboutus");
          break;
        case 2:
          this.$router.push("/introduce");
          break;
        case 3:
          this.$router.push("/product");
          break;
        case 4:
          this.$router.push("/contact");
          break;
        
      }
    },
  },

  watch: {},
};
</script>
<style scoped>
.uls{
  list-style: none;
  padding: 10px;
  color: #fff;
  position: fixed;
  background: #000;
  top: 90px;
  right: 150px;
}
.uls li{
  width: 160px;
  padding: 5px;
  border-radius: 5px;
  text-align: left;
}
.uls li:hover{
  background: #ff865b;
  cursor: pointer;
}
.nav_cont {
  margin-left: 10px;
  opacity: 0.6;
  width: 140px;
  height: 42px;
  text-align: center;
  float: left;
  cursor: pointer;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 42px;
}
.tabspanactive {
  opacity: 1;
  width: 140px;
  height: 42px;
  text-align: center;
  background: #ff865b;
  border-radius: 14px;
}
.head {
  padding: 34px 30px 0px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 900;
  width: calc(100% - 60px);
}
.logotil {
  font-size: 30px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #df6331;
  margin-left: 30px;
}
.toito {
  display: flex;
  align-items: center;
}
</style>
<style>
.el-dropdown .el-button{
  width: 140px!important;
  opacity: 1;
  height: 42px!important;
  padding: 0px;
  text-align: center;
  background: none;
  border-radius: 14px;
}
.el-button {
  border: none !important;
}
/* .el-button--primary {
  background: #ff865b !important;
  border-color: 1px solid #ff865b !important;
  top: 0;
} */
.el-button--primary:hover {
  background: #ff865b !important;
  border-color: 1px solid #ff865b !important;
}
</style>