<template>
  <div class="home">
    <div class="sharebg">
      <p class="share_title">解决方案&案例分享</p>
      <div class="share_cont">
        <span
          v-for="(item, index) in gorver"
          :key="index"
          :class="{ sharespanactive: index === activeshare }"
          >{{ item.word1 }}</br>{{ item.word2 }}</span
        >
      </div>
    </div>
    <div style="position: absolute; top: 0px; width: 100%;height:100%">
      <img src="../assets/img/homebg2.png" style="width: 100%;height:100%" />
      <div class="mes">
        <el-carousel trigger="click" height="240px" :initial-index='0' ref="carousel">
          <el-carousel-item v-for="(item, index) in shares" :key="index">
            <p style="color: #fff; font-size: 26px">{{ item.title }}</p>
            <span style="color: #fff; font-size: 16px">
              {{ item.content }}
            </span>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <Head :active="5" ></Head>
        <Service></Service>
    <Foot></Foot>
  </div>
</template>
<script>
import Foot from "./foot";
import Head from "./head";
import Service from "./service";
export default {
  components: { Foot, Head ,Service},
  name: "index",
  data() {
    return {
      activeshare: 0,
      currentIndex: 2,
      gorver: [
        { word1: "政府", word2: "机构" },
        { word1: "企业", word2: "单位" },
        { word1: "金融", word2: "机构" },
      ],
      shares: [
        {
          title: "政府机构 — 城运管理系统",
          content:
            "城运系统是有一个专有名词，叫做数字孪生。之所以引进数字孪生技术是因为我们发现，数字孪生城市是建设新型智慧城市的一条新兴技术路径， 是城市智能化、运营可持续化的前沿先进模式，也是吸引高端智力资源共同参与，持续迭代更新的城市级创新平台。",
        },
        {
          title: "政府机构 — 一标六实智慧公安平台",
          content:
            "实有人口：包含人员姓名，身份证号，户籍地址等信息。 实有房屋：包含街道，居委，地址和房间号（住宅建筑）/楼层（商业 建筑）等信息。 实有单位：包含街道，居委，地址和房间号等信息。",
        },
        {
          title: "政府机构 — 管廊与管网",
          content:
            "场景可以整体以白模或蓝模的形式展现 简洁、超大范围一屏显示。 地下隧道中的结构，以及各类管线。",
        },
        {
          title: "企业单位 — 智慧办公",
          content:
            "办公是现代社会生产活动中重要的组成部分。在互联网完成衣、食、住、行四类简单的个人活动的在线化后，作为更加复杂的群体人类活动，办公在线化正伴随着数字化城市浪潮，被推向了当代风口浪尖。",
        },
        {
          title: "企业单位 — 智慧会议",
          content: "会议召集、会议准备、会议召开、会后管理。",
        },
        {
          title: "企业单位 — RPA",
          content:
            "将可定义、重复 性高、有规则的 软件操作实现自 动化的软件工具；通过学习并模仿 人的操作，生成 提高流程操作的 效率和安全可靠 性的数字员工。",
        },
        {
          title: "企业单位 — 智慧物流",
          content:
            "物流运单中心、物流运营中心、物流财务中心、物流车辆管理、物流决策分析、物流公司管理。",
        },
        {
          title: "金融机构 — 量化交易",
          content:
            "量化系统统一了回测、仿真交易和真实交易的策略代码，为量化投资者提供一套完善的量化交易流程，大大缩短了从策略研究到真实交易运行的整个过程。",
        },
        {
          title: "企业单位 — 智能投资管理系统",
          content:
            "智能投资管理系统作为支持金融市场综合交易的系统，基于分布式架构的底层设计、并行处理技术实现行情处理、交易管理、风险控制、账户管 理、数据核算、绩效评估以 及归因分析、日志记录和信息查询等功能，可以事前、事中、事后实现人员、风险、交易的 有效管理。",
        },
      ],
    };
  },
  methods: {
    test(index){
      this.$refs.carousel.setActiveItem(index);
    },
    navshareclick(item, index) {
      this.activeshare = index;
    },
  },

  watch: {},
};
</script>
<style scoped>
.home {
  width: 100%;
  position: relative;
  height: calc(100vh - 52px);
}

.share_title {
  text-align: center;
  font-size: 40px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #fff;
}
.share {
  width: 100%;
  height: 1000px;
  background: #171919;
  position: relative;
}

.share_cont {
  width: 60%;
  height: 104px;
  display: flex;
  padding: 0 20%;
  align-items: center;
  justify-content: space-between;
}

.share_cont span {
  display: block;
  font-size: 20px;
  float: left;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 104px;
  text-align: center;
  height: 104px;
  background: #ff865b !important;
  border-radius: 10px;
}
.sharebg {
  width: 800px;
  height: 600px;
  position: absolute;
  left: 2%;
  top: 200px;
  z-index: 999999;
  background-image: url(../assets/img/Map.png);
  background-size: 100%;
  background-repeat: no-repeat;
}
.mes {
  position: absolute;
  top: 200px;
  padding: 100px 100px 200px 100px;
  right: 100px;
  width: 500px;
  height: 200px;
  background-image: url(../assets/img/xiebg.png);
  background-size: 100%;
  background-repeat: no-repeat;
}
</style>
<style>
.mes .el-carousel .el-carousel__indicators--horizontal {
  bottom: 5% !important;
  left: 1% !important;
  transform: translateX(0%) !important;
}

.mes
  .el-carousel
  .el-carousel__indicators
  .el-carousel__indicator
  .el-carousel__button {
  width: 14px !important;
  height: 14px !important;
  background: #ffffff !important;
  margin-left: 10px !important;
  border-radius: 50%;
}
</style>