<template>
  <div class="home">
        <div class="About" id="AboutId" name="jumpitem">
            <div class="lines_one"></div>
            <div class="lines_two"></div>
            <div class="lines_three"></div>
            <div class="About_bg"></div>
            <div class="About_bgword">Product Introduction</div>
            <div class="About_title">产品介绍</div>
            <div class="About_word">
                EPI 智能交易客户端是由翌派科技独立设计、开发的交易终端软件。开发团
队基于交易者自身需求出发，深度了解交易者的交易习惯和痛点，进行了大胆的
创新尝试，自主开发了 EPI 交易客户端，让交易变得更简单。
            </div>
            <div class="probox"> 
              <div class="proboxnav">
                <div class="parnav">
                  <div style="width:50px;height:80px;background:rgba(217,217,217,0.5);float:left;line-height:80px;font-size:30px;color:#fff">01</div>
                  <div class="contentbox">行情交易</div>
                  <div class="xzbox"></div> 
                </div>
                <div style="color:#fff">------------------</div>
                <div class="words">低延迟高流畅，提供多市场多品种全行情以及量化数据指标展示，交易员可以</br>实时查看行情数据，进行下单并查看委托订单、持仓、成交等一系列与投资决策</br>相关的信息。</div>
              </div>
              <div class="proboxnav">
                <div class="parnav">
                  <div style="width:50px;height:80px;background:rgba(217,217,217,0.5);float:left;line-height:80px;font-size:30px;color:#fff">02</div>
                  <div class="contentbox">个体化窗口</div>
                  <div class="xzbox"></div> 
                </div>
                <div style="color:#fff">------------------</div>
                <div class="words">数据本地运行，管理安全，性能强劲，支持多窗体自定义设置布局，支持多品种</br>多周期分时图鲁加，页面布局灵活调整，高流畅度，提供量化数据指标展示。</div>
              </div>
              <div class="proboxnav">
                <div class="parnav">
                  <div style="width:50px;height:80px;background:rgba(217,217,217,0.5);float:left;line-height:80px;font-size:30px;color:#fff">03</div>
                  <div class="contentbox">高级算法交易</div>
                  <div class="xzbox"></div> 
                </div>
                <div style="color:#fff">------------------</div>
                <div class="words">交易高效便捷，提供多种交易方式，支持GTC单、本地预埋单、自定义套利组合</br>下单(跨合约、跨品种、跨交易所)、止盈止损单、条件单等功能，满足交易者</br>的需要。</div>
              </div>

              <div style="display:flex;align-items: center;">
                <div class="parnav" style="float:left">
                  <div style="width:50px;height:80px;background:rgba(217,217,217,0.5);float:left;line-height:80px;font-size:30px;color:#fff">04</div>
                  <div class="contentbox">软件下载</div>
                  <div class="xzbox"></div> 
                </div>
                <div style="color:#fff;float:left">------------------</div>
                <div>
                  <div style="display:flex;align-items: center;margin-left: 20px;">
                    <div style="color:#fff">简易版：只提供行情、下单等基本功能</div>
                    <div class="mysubmit" @click="dowloadsz('1')">立即下载</div>
                  </div>
                  <div style="display:flex;align-items: center;margin-left: 20px;margin-top: 10px;">
                    <div style="color:#fff">专业版：支持高级算法，多窗体，行情交易等功能</div>
                    <div class="mysubmit" @click="dowloadsz('2')">立即下载</div>
                  </div>
                  <div style="display:flex;align-items: center;margin-left: 20px;margin-top: 10px;">
                    <div style="color:#fff">小时钟：给交易员提供精确的时钟提醒功能</div>
                    <div class="mysubmit" @click="dowloadsz('3')">立即下载</div>
                  </div>
                </div>
              </div>
            </div>
            


        </div>
        <Service></Service>
     <Head :active="3"></Head>
        <Foot></Foot>
    </div>
</template>
<script>
import Service from "./service";
import Foot from "./foot";
import Head from "./head";
export default {
  components: { Foot, Head,Service },
  name: "index",
  data() {
    return {
    };
  },
  methods: {
    dowloadsz(type){
      switch (type) {
        case '1':
          window.open('https://dptest.milkway.tech:2337/epilite/EPILITE_Setup.zip')
          break;
        case '2':
          window.open('https://dptest.milkway.tech:2337/tradeversion/EPI_Pro.zip')
          break;
        case '3':
          window.open('https://dptest.milkway.tech:2337/huaclock/HuaClock.zip')
          break;
      }
      
    },
  },

  watch: {},
};
</script>
<style scoped>
.contentbox{
  width:150px;height:80px;background:rgba(52,65,89,1);float:left;line-height:80px;font-size:20px;color:#fff;
}
.words{
  margin-left: 20px;
  color: #fff;
  text-align: left;
}
.xzbox{
      width: 56px;
    height: 56px;
  z-index: -50;
    background: rgb(52, 65, 89);
    float: left;
    line-height: 80px;
    font-size: 30px;
    color: rgb(255, 255, 255);
    transform: rotate(45deg);
    position: absolute;
    right: 21px;
    top: 12px;
}
.proboxnav{
    line-height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.probox{
    position: absolute;
    margin-left: -350px;
    z-index: 60;
    left: 45%;
    top: 34%;
}
.parnav{
  position: relative;
  width: 250px;
  cursor: pointer;
  height: 80px;
}
.home {
  width: 100%;
  position: relative;
  height: calc(100vh - 52px);
}
.lines_one {
  width: 1px;
  z-index: 20;
  position: absolute;
  left: 168px;
  top: 0%;
  height: 100%;
  background: #ffffff;
  opacity: 0.06;
}
.mysubmit {
  width: 120px;
  margin-left: 25px;
  font-weight: 800;
  cursor: pointer;
  font-size: 12px;
  height: 30px;
  background: rgba(52,65,89,1);
  text-align: center;
  line-height: 30px;
  border-radius: 10px;
  float: right;
  color: #fff;
}
.lines_two {
  width: 1px;
  z-index: 20;
  position: absolute;
  left: 40%;
  top: 0%;
  height: 100%;
  background: #ffffff;
  opacity: 0.06;
}

.lines_three {
  width: 1px;
  z-index: 20;
  position: absolute;
  left: 70%;
  top: 0%;
  height: 100%;
  background: #ffffff;
  opacity: 0.06;
}

.About {
  width: 100%;
  height: 100%;
  background: #171919;
  position: relative;
}

body::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  background-color: transparent;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
body::-webkit-scrollbar-track {
  background-color: #555;
  border-radius: 1px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/*定义滚动条轨道 内阴影+圆角*/
body::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 1px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.About_bgword {
  font-size: 200px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #ffffff;
  line-height: 156px;
  position: absolute;
  left: -5%;
  z-index: 50;
  opacity: 0.02;
  top: 13%;
}

.About_title {
  font-size: 40px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #ffffff;
  z-index: 60;
  position: absolute;
  left: 50%;
  margin-left: -350px;
  top: 12%;
}

.About_word {
  width: 700px;
  font-size: 18px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  position: absolute;
  margin-left: -350px;
  z-index: 60;
  opacity: 0.7;
  left: 50%;
  top: 19%;
  line-height: 32px;
}

.icons {
  height: 400px;
  width: 400px;
  color: #ffffff;
  position: absolute;
  margin-left: -205px;
  left: 50%;
  top: 50%;
  transform: rotate(45deg);
  line-height: 32px;
}

.icon_o {
  width: 185px;
  /* transform: rotate(5deg); */
  margin-left: 15px;
  margin-top: 15px;
  height: 185px;
  float: left;
  background: rgba(16, 33, 49, 0.5);
  /* box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.49); */
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* opacity: 0.5; */
}

.icon_o_c {
  width: 104px;
  transform: rotate(45deg);
  height: 114px;
  padding-left: 10px;
  background: linear-gradient(180deg, #253659 0%, #102131 100%);
  background: #253659;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #ff865b;
  line-height: 110px;
  opacity: 1;
  text-align: center;
}

.icon-word {
  width: 108px;
  /* height: 112px; */
  margin-left: -24px;
  transform: rotate(-90deg);
  opacity: 1;
  line-height: 22px;
}

.About_bg {
  background-image: url(../assets/img/Map1.png);
  background-size: 100%;
  height: 534px;
  width: 1246px;
  position: absolute;
  left: 10%;
  top: 38%;
  background-repeat: no-repeat;
}
</style>
<style>
.luobo .el-carousel .el-carousel__indicators--horizontal {
  bottom: 5% !important;
  right: 5% !important;
  left: unset !important;
  transform: translateX(0%) !important;
}
.luobo .el-carousel .el-carousel__container {
  height: 100%;
}
.luobo
  .el-carousel
  .el-carousel__indicators
  .el-carousel__indicator
  .el-carousel__button {
  width: 200px !important;
  height: 4px !important;
  background: #ffffff !important;
  margin-left: 30px !important;
}

.luobo .el-carousel .el-carousel__indicators .is-active .el-carousel__button {
  background: #ff865b !important;
}
</style>